import React, { useEffect, useState } from "react";
import {
  faAward,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../../components/common/header";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Modal from "../../components/common/modalRewardRedeem";
import ReactLoading from "react-loading";
import RewardCard from "../../components/profile/RewardCard";
import UserService from "../../services/UserService";
import cookie from "react-cookies";
import { useHistory } from "react-router-dom";

const ProfilePage = ({ line_profile, line_oa }) => {
  const history = useHistory();
  const [rewards, setRewards] = useState([]);
  const [reward, setReward] = useState(null);
  const [profile, setProfile] = useState(null);
  const [showreward, setShowreward] = useState(false);
  const [loadingReward, setLoadingReward] = useState(true);

  useEffect(() => {
    getProfile();
    getRewards();
  }, []);

  const getRewards = async () => {
    await UserService.rewards().then((res) => {
      setRewards(res.data);
      setLoadingReward(false);
    });
  };

  const getProfile = async () => {
    await UserService.profile(cookie.load("token")).then((res) => {
      setProfile({
        ...res.data,
        full_name: res.data.first_name + " " + res.data.last_name,
      });
    });
  };

  const getGender = (gender) => {
    switch (gender) {
      case "M":
        return "ชาย";
      case "F":
        return "หญิง";
      default:
        return "อื่น ๆ";
    }
  };

  return (
    <>
      <Helmet>
        <title>สมาชิก</title>
      </Helmet>
      {profile && (
        <div id="profile" className="bggrey">
          <Header
            text={"แจ้งตรวจสอบผลิตภัณฑ์"}
            onBack={() => history.push(`/${line_oa}/product-report`)}
          />
          <div className="pt-3 bluebg50px container">
            <div className="col-12 row">
              <div className="col-4 h-100 ">
                <img
                  src={line_profile?.pictureUrl || "/profile.png"}
                  className="profileimage"
                />
              </div>
              <div className="col-8 text-white my-auto p-0">
                <h2 className="fullname">{profile?.full_name}</h2>
                <div className="pt-2">
                  เพศ {profile?.gender ? getGender(profile.gender) : "gender"}
                  <span className="age-box ms-2 ps-2">
                    อายุ {profile?.age || "age"} ปี
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 row m-0 mt-3">
              <div className="col-6 ps-0">
                <Link to={`/${line_oa}/history-report`}>
                  <button className="button--white w-100">
                    ประวัติการตรวจสอบ
                  </button>
                </Link>
              </div>
              <div className="col-6 pe-0">
                <Link to={`/${line_oa}/edit-profile`}>
                  <button className="button--white w-100">
                    แก้ไขข้อมูลส่วนตัว
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container py-4 d-flex">
            <h3 className="align-self-center">
              <FontAwesomeIcon icon={faAward} className="me-2" />
              คะแนนปัจจุบัน
            </h3>
            <h1 className="align-self-center dark-blue-600 ms-auto pe-2">
              {profile?.score || 0}
            </h1>
            <p className="align-self-center m-0">คะแนน</p>
          </div>
          <div className="bgwhite container">
            <div className="content-rewards">
              <div className="pt-3">
                <h3 className="semibold ">ของรางวัลที่แลกได้</h3>
                {rewards && !loadingReward ? (
                  <>
                    <div>
                      {rewards.length !== 0 ? (
                        rewards.map((reward, i) => {
                          return (
                            <div
                              key={i}
                              className={`${!showreward && i >= 5 && "d-none"}`}
                              onClick={() => {
                                setReward(reward);
                                window.scrollTo(0, 0);
                              }}
                            >
                              <RewardCard {...reward} />
                              <hr className="mb-0" />
                            </div>
                          );
                        })
                      ) : (
                        <div className="not-have-rewards">
                          <div>
                            <img src="/icon/gift-icon.png" width="80px"></img>
                          </div>
                          <div className="pt-3">ยังไม่มีของรางวัลให้แลก</div>
                        </div>
                      )}
                    </div>
                    <div
                      className={`text-center py-3 ${showreward && "d-none"} ${
                        rewards.length <= 5 && "d-none"
                      }`}
                      onClick={() => setShowreward(true)}
                    >
                      <span>
                        ดูเพิ่มเติม <FontAwesomeIcon icon={faChevronDown} />
                      </span>
                    </div>
                    <div
                      className={`text-center py-3 ${!showreward && "d-none"}`}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setShowreward(false);
                      }}
                    >
                      <span>
                        ดูน้อยลง <FontAwesomeIcon icon={faChevronUp} />
                      </span>
                    </div>
                  </>
                ) : (
                  <div className="text-center pt-5">
                    <ReactLoading
                      type={"balls"}
                      color={"#B4BECB"}
                      width={40}
                      className="mx-auto"
                    />
                  </div>
                )}
              </div>
            </div>
            {reward && (
              <Modal
                closeModal={() => setReward(null)}
                setReward={setReward}
                reward={reward}
                profile={profile}
                getProfile={getProfile}
                getRewards={getRewards}
              ></Modal>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default ProfilePage;
