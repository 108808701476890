import { InputField, TextArea } from "../common/inputField";
import React, { useEffect, useState } from "react";
import { faImage, faPen } from "@fortawesome/free-solid-svg-icons";

import Button from "../common/button";
import Camera from "./Camera";
import Compress from "compress.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../common/header";
import HeaderStep from "../common/headerStep";
import Swal from "sweetalert2";

export default function Step2({ report, setReport, onChange, onBack }) {
  const [name, setName] = useState(report.product.name || "");
  const [description, setDescription] = useState(report.product.detail || "");
  const [image, setImage] = useState(report.product.image || "");
  const [imageUrl, setImageUrl] = useState(
    report.product.image ? URL.createObjectURL(report.product.image) : ""
  );
  const [openCamera, setOpenCamera] = useState(false);

  const blobUrlToFile = async (img, fileName) => {
    let blob = await fetch(img).then((r) => r.blob());
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    setImage(blob);
    setImageUrl(URL.createObjectURL(blob));
    setOpenCamera(false);
  };

  const uploadImage = async (img) => {
    let filesize = (img.size / 1000 / 1000).toFixed(4); // MB
    if (filesize < 2) {
      setImage(img);
      setImageUrl(URL.createObjectURL(img));
      setOpenCamera(false);
    } else {
      let imgResize = await resizeImageFn(img)
      setImage(imgResize);
      setImageUrl(URL.createObjectURL(imgResize));
      setOpenCamera(false);
    }
  };

  const resizeImageFn = async (file) => {
    let compress = new Compress();
    const resizedImage = await compress.compress([file], {
      size: 2, // the max size in MB, defaults to 2MB
      quality: 1, // the quality of the image, max is 1,
      resize: true, // defaults to true, set false if you do not want to resize the image width and height
    });
    const img = resizedImage[0];
    const base64str = img.data;
    const imgExt = img.ext;
    const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt);
    return resizedFiile;
  };

  const onAddProduct = (event) => {
    event.preventDefault();
    if (name !== "") {
      setReport({
        ...report,
        product: {
          name: name,
          detail: description,
          image: image,
        },
      });
      onChange();
    }
  };

  if (!openCamera) {
    return (
      <div>
        <Header onBack={onBack} />
        <HeaderStep icon={<FontAwesomeIcon icon={faPen} />}>
          ข้อมูลผลิตภัณฑ์
        </HeaderStep>
        <form onSubmit={onAddProduct}>
          <div className="container content-back">
            <div>
              <InputField
                require={true}
                type="text"
                name="ชื่อผลิตภัณฑ์"
                value={name}
                change={setName}
                placeholder={"ชื่อผลิตภัณฑ์"}
              />
            </div>
            <div className="mt-3">
              <TextArea
                name="รายละเอียดเพิ่มเติม"
                value={description}
                change={setDescription}
                placeholder={"พิมพ์ข้อความที่นี่"}
              />
            </div>
            <div className="mt-3">
              <label>รูปผลิตภัณฑ์</label>
              <div
                className="select-image"
                // type="button"
                onClick={() => setOpenCamera(true)}
              >
                <FontAwesomeIcon style={{ fontSize: "20px" }} icon={faImage} />
                <div className="mt-2">คลิกเพื่อถ่ายหรือแนบรูป</div>
              </div>
            </div>
            {imageUrl && <img src={imageUrl} className="w-100 mt-3 mb-5" />}
          </div>
          <div
            className={`container ${
              image == "" ? "fixed-button-bottom" : "py-3 bg-light"
            } `}
            style={{
              position: image !== "" && "fixed",
              bottom: image !== "" && 0,
            }}
          >
            <Button disabled={!name} type="submit" className="w-100">
              ถัดไป
            </Button>
          </div>
          {/* )} */}
        </form>
      </div>
    );
  } else {
    return (
      <Camera
        className={`input-photo`}
        onTakePhoto={blobUrlToFile}
        uploadImage={uploadImage}
        setOpenCamera={setOpenCamera}
      />
    );
  }
}
