import React, { useEffect, useState } from "react";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import Button from "../common/button";
import Header from "../common/header";
import HeaderStep from "../common/headerStep";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CameraComponent(props) {
  const [isCamera, setIsCamera] = useState(false);
  window.scrollTo(0, 0);
  return (
    <div {...props}>
      {isCamera && (
        <>
          <div
            id="header"
            className="w-100"
            style={{ position: "absolute", zIndex: 2000 }}
          >
            <Header onBack={() => setIsCamera(false)} />
            <HeaderStep icon={<FontAwesomeIcon icon={faCamera} />}>
              กล้อง
            </HeaderStep>
          </div>
          <Camera
            idealFacingMode="environment"
            isImageMirror={false}
            onTakePhoto={(dataUri) => {
              setIsCamera(false);
              props.onTakePhoto(dataUri);
            }}
          />
        </>
      )}
      {!isCamera && (
        <>
          <Header onBack={() => props.setOpenCamera(false)} />
          <HeaderStep icon={<FontAwesomeIcon icon={faImage} />}>
            อัพโหลดรูปภาพ
          </HeaderStep>
          <div className="container content">
            <Button className="w-100 mb-3" onClick={() => setIsCamera(true)}>
              ถ่ายภาพจากกล้อง
            </Button>
            <input
              type="file"
              accept="image/*"
              id="actual-btn"
              hidden
              onChange={(e) => props.uploadImage(e.target.files[0])}
            />
            <label for="actual-btn" className="label--button-upload w-100 pt-3">
              <h4>เลือกรูปภาพจากคลัง</h4>
            </label>
          </div>
        </>
      )}
    </div>
  );
}
