import React from "react";
import Header from "../common/header";
import HeaderStep from "../common/headerStep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import Button from "../common/button";

function ConfirmReport({ onChange, onBack }) {
  return (
    <div>
      <Header onBack={onBack} />
      <HeaderStep icon={<FontAwesomeIcon icon={faCheckCircle} />}>
        ยืนยันการแจ้งเรื่อง
      </HeaderStep>
      <div className="container content-back confirm">
        <p style={{ color: "#697587" }}>
          ข้อมูลที่แจ้งเข้ามาจะถูกจัดเก็บเป็นความลับ
          โดยหลังจากผู้แจ้งกดปุ่มถัดไปด้านล่างนี้ ผู้แจ้ง
          ยินยอมให้สำนักงานคณะกรรมการอาหารและยา
          นำข้อมูลการแจ้งนี้ไปใช้ในการวิเคราะห์ผล เพื่อนำไปสร้างสื่อ องค์ความรู้
          และใช้ในงาน วิชาการ
          ซึ่งจะก่อให้เกิดคุณประโยชน์แก่ประชาชนในประเทศต่อไปในอนาคต
        </p>
      </div>
      <div className="container fixed-button-bottom">
        <div>
          <p className="text-center">
            ในหน้าต่างถัดไป กรุณากด <b style={{ color: "#246CBF" }}>“อนุญาต”</b>{" "}
            เพื่อให้ระบบสามารถจัดเก็บพิกัดของผู้แจ้งได้
          </p>
        </div>
        <Button className="w-100" onClick={() => onChange()}>
          ถัดไป
        </Button>
      </div>
    </div>
  );
}

export default ConfirmReport;
