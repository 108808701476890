import { Helmet } from "react-helmet";

const HomePage = () => {
  return (
    <div id="index">
      <Helmet>
        <title>Loading...</title>
      </Helmet>
      <div className="content">
        <div className="index-text text-center">FDA</div>
      </div>
    </div>
  );
};

export default HomePage;
