import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";

import UserService from "../services/UserService";
import cookie from "react-cookies";

const withAuth = (WrappedComponent, line_oa) => (props) => {
  let location = useLocation();
  const [state, setState] = useState({ loading: true, isUser: false });

  useEffect(() => {
    CheckUser();
  }, []);

  const CheckUser = async () => {
    await UserService.check_user(props.line_profile.userId)
      .then((res) => {
        cookie.save("token", res.data.token);
        setState({ isUser: true, loading: false });
      })
      .catch((_) => {
        cookie.remove("token");
        setState({ ...state, loading: false });
      });
  };

  if (!state.loading) {
    if (state.isUser) {
      return <WrappedComponent {...props} />;
    } else {
      return (
        <Redirect
          exact
          to={`/${line_oa}/register?redirect=${location.pathname}`}
        />
      );
    }
  }
  return <div></div>;
};

export default withAuth;
