import React, { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

function GetLocation({ onChange }) {
  useEffect(() => {
    // console.log(coords, isGeolocationEnabled)
    // if (!coords && !isGeolocationEnabled) {
    //   Swal.fire({
    //     icon: "error",
    //     text: "ไม่สามารถดึงที่อยู่ของคุณได้",
    //   }).then(() => {
    //     onChange("", "");
    //   });
    // } else {
    //   if (coords) {
    //     onChange(coords.latitude, coords.longitude);
    //   }
    // }
    getLocation();
  }, []);

  const getLocation = () => {
    if (!navigator.geolocation) {
      console.log("not get location");
      onChange("", "");
    } else {
      console.log("getting location...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("get location success");
          onChange(position.coords.latitude, position.coords.longitude);
        },
        () => {
          console.log("get location failed");
          Swal.fire({
            icon: "error",
            text: "ไม่สามารถดึงที่อยู่ของคุณได้",
          }).then(() => {
            onChange("", "");
          });
        }
      );
    }
  };

  return (
    <div className="loading-page">
      <div className="row align-items-center h-100">
        <div>
          <div className="text-center">
            <FontAwesomeIcon className="icon" icon={faMapMarkerAlt} />
            <p>Loading...</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetLocation;
