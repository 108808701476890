import React, { useState } from "react";

import Button from "./button";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";
import UserService from "../../services/UserService";
import cookie from "react-cookies";

const editorConfiguration = {
  toolbar: [],
};

const ModalReportProductDanger = ({
  profile,
  closeModal,
  line_oa,
  productReport,
  level,
}) => {
  const [message, setMessage] = useState(
    `<p>แจ้งรายงานผลิตภัณฑ์จาก LINE Official Account ชื่อบัญชี ${
      line_oa === "fda-people" ? "FDA People" : ""
    }${line_oa === "fda-thai" ? "FDA Thai" : ""}</p><p>ชื่อผลิตภัณฑ์: ${
      productReport.product_name
    }<br>รายละเอียดเพิ่มเติม: ${productReport.detail || "-"}<br>ผลลัพธ์: ${
      level.levelText
    }</p><p>ชื่อผู้แจ้ง: ${profile.first_name} ${
      profile.last_name
    }<br>เบอร์โทรศัพท์: ${
      profile.tel.substring(0, 3) +
      "-" +
      profile.tel.substring(3, 6) +
      "-" +
      profile.tel.substring(6, 10)
    }</p>`
  );
  const sendMailHighRisk = async () => {
    Swal.fire({
      text: "ยืนยันเพื่อแจ้งเรื่องใช่หรือไม่ ?",
      showCancelButton: true,
      confirmButtonText: `ยืนยัน`,
      confirmButtonColor: "#00930b",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {
          mailBody: message,
        };
        await UserService.product_report_by_id_sendmail(
          productReport.id,
          cookie.load("token"),
          data
        )
          .then((_) => {
            Swal.fire({
              icon: "success",
              text: "แจ้งข้อมูลเรียบร้อย",
            }).then((_) => {
              closeModal(false);
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              text: "แจ้งข้อมูลล้มเหลว",
              confirmButtonColor: "#00930b",
              confirmButtonText: `ตกลง`,
            }).then((_) => {
              closeModal(false);
            });
          });
      }
    });
  };

  return (
    <>
      <div id="modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div
              className="btn-report-back"
              onClick={() => {
                setMessage("");
                closeModal(false);
              }}
            >
              <b>X</b> ปิดหน้าต่าง
            </div>
            <div className="modal-body pt-4">
              <div className="modal-body-content border-bottom-0">
                <h2 className="name">รายงานผลิตภัณฑ์</h2>
                <p>
                  ท่านสามารถแจ้งรายงานผลิตภัณฑ์เพื่อให้หน่วยงานดำเนินการตรวจสอบต่อไป
                </p>
                <div className="mt-3">
                  <p className="mb-1">
                    <span>ข้อความแจ้งรายงาน</span>
                    <span style={{ color: "red" }}> *</span>
                  </p>
                  <CKEditor
                    editor={ClassicEditor}
                    config={editorConfiguration}
                    data={message}
                    // onReady={(editor) => {
                    //   // You can store the "editor" and use when it is needed.
                    //   // console.log("Editor is ready to use!", editor);
                    // }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setMessage(data);
                    }}
                    // onBlur={(event, editor) => {
                    //   console.log("Blur.", editor);
                    // }}
                    // onFocus={(event, editor) => {
                    //   console.log("Focus.", editor);
                    // }}
                  />
                </div>
                <Button
                  disabled={!message}
                  onClick={() => {
                    console.log("resrerser");
                    sendMailHighRisk();
                    // validEmailandTel();
                  }}
                  className={`w-100 mt-3`}
                >
                  แจ้งรายงาน
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalReportProductDanger;
