import { InputField, TextArea } from "./inputField";
import React, { useState } from "react";

import Button from "./button";
import Swal from "sweetalert2";
import UserService from "../../services/UserService";
import cookie from "react-cookies";
import validator from "validator";

function ModalRewardRedeem(props) {
  const [address, setAddress] = useState({
    first_name: props.profile.first_name,
    last_name: props.profile.last_name,
    tel: props.profile.tel,
    address: "",
  });
  const [validtel, setValidtel] = useState(true);

  const validTel = () => {
    setValidtel(false);
    if (validator.isMobilePhone(address.tel, "any")) {
      if (validator.isLength(address.tel, 10, 10)) {
        setValidtel(true);
        return true;
      }
      return false;
    }
  };

  const validEmailandTel = async () => {
    let checkValidTel = validTel();
    if (checkValidTel && validTel) {
      redeemReward(props.reward.id, address);
    }
  };

  const redeemReward = async (id, address) => {
    Swal.fire({
      text: "ยืนยันเพื่อแลกของรางวัลใช่หรือไม่ ?",
      showCancelButton: true,
      confirmButtonText: `ยืนยัน`,
      confirmButtonColor: "#00930b",
      cancelButtonText: `ยกเลิก`,
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await UserService.redeem_reward(id, address, cookie.load("token"))
          .then((_) => {
            Swal.fire({
              icon: "success",
              text: "แลกของรางวัลสำเร็จ",
            }).then((_) => {
              props.setReward(null);
              props.getProfile();
              props.getRewards();
            });
          })
          .catch((err) => {
            let status = "";
            if (err.response.data.status === 101) {
              status = "คะแนนของคุณไม่เพียงพอ";
            } else {
              status = "ของรางวัลชิ้นนี้หมดแล้ว";
            }
            Swal.fire({
              icon: "error",
              text: status,
              confirmButtonColor: "#00930b",
              confirmButtonText: `ตกลง`,
            }).then((_) => {
              props.setReward(null);
              props.getProfile();
              props.getRewards();
            });
          });
      }
    });
  };

  return (
    <>
      <div id="modal" {...props}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header p-0">
              <div
                className="btn-reward-back"
                onClick={() => {
                  setAddress("");
                  props.closeModal();
                }}
              >
                <b>X</b> ปิดหน้าต่าง
              </div>
              <img
                className="cover"
                src={`${
                  process.env.REACT_APP_IMAGE_URL +
                    "/" +
                    props.reward.cover_image || "/profile.png"
                }`}
              />
            </div>
            <div className="modal-body">
              <div className="modal-body-content">
                <h2 className="name">{props.reward.name}</h2>
                <p>ใช้คะแนนเพื่อแลก {props.reward.require_score} คะแนน</p>
                <div>
                  <p className="mb-0">
                    <b>เงื่อนไขในการแลกของรางวัล</b>
                  </p>
                  <p>
                    1. คุณยอมรับข้อตกลงในการแลกของรางวัล
                    และยินดีให้สำนักงานคณะกรรมการอาหารและยาจัดเก็บข้อมูลส่วนบุคคล
                    เพื่อนำไปใช้ในการวิเคราะห์
                  </p>
                  <p>
                    2. คุณยอมรับข้อตกลงในการแลกของรางวัล
                    และยินดีให้สำนักงานคณะกรรมการอาหารและยาจัดเก็บข้อมูลส่วนบุคคล
                    เพื่อนำไปใช้ในการวิเคราะห์
                  </p>
                </div>
              </div>
            </div>
            <div className="modal-body">
              <div className="modal-body-form">
                <p className="text-center">
                  กรุณากรอกข้อมูลด้านล่างนี้เพื่อแลกของรางวัล
                </p>
                <div className="mt-3">
                  <div className="mt-3">
                    <InputField
                      name="ชื่อ"
                      value={address.first_name}
                      change={(value) =>
                        setAddress({ ...address, first_name: value })
                      }
                      require={true}
                    />
                  </div>
                  <div className="mt-3">
                    <InputField
                      name="นามสกุล"
                      value={address.last_name}
                      change={(value) =>
                        setAddress({ ...address, last_name: value })
                      }
                      require={true}
                    />
                  </div>
                  <div className="mt-3">
                    <InputField
                      type="tel"
                      name="เบอร์โทรศัพท์ที่สามารถติดต่อได้"
                      value={address.tel}
                      change={(value) => setAddress({ ...address, tel: value })}
                      require={true}
                      onBlur={() => validTel()}
                    />
                    {!validtel && (
                      <div style={{ color: "red" }}>
                        กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง
                      </div>
                    )}
                  </div>
                  <div className="mt-3">
                    <TextArea
                      name="ที่อยู่"
                      value={address.address}
                      change={(value) =>
                        setAddress({ ...address, address: value })
                      }
                      require={true}
                    />
                  </div>
                </div>
                <p className="warning-text">
                  กรุณาตรวจสอบข้อมูลให้ถูกต้อง
                  <br />
                  เมื่อบันทึกข้อมูลแล้ว จะไม่สามารถแก้ไขได้อีก
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                disabled={
                  !address.first_name ||
                  !address.last_name ||
                  !address.tel ||
                  !address.address
                }
                onClick={() => {
                  validEmailandTel();
                }}
                className={`w-100`}
              >
                แลกของรางวัล
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalRewardRedeem;
