import Button from "../common/button";
import ButtonSelection from "./buttonSelection";

export default function Question({
  question,
  answer_selected,
  addAnswer,
  onNext,
}) {
  return (
    <div>
      <div className="content-back">
        {question.survey_choices.map((choice) => {
          return (
            <div key={choice.id} style={{ marginBottom: "16px" }}>
              <ButtonSelection
                selected={answer_selected && answer_selected === choice.id}
                className="w-100"
                onClick={() => addAnswer(question.id, choice.id)}
              >
                {choice.choice_content}
              </ButtonSelection>
            </div>
          );
        })}
      </div>
      <div className="fixed-button-bottom w-100">
        <div className="container">
          <Button
            className="w-100"
            disabled={!answer_selected}
            onClick={onNext}
          >
            ถัดไป
          </Button>
        </div>
      </div>
    </div>
  );
}
