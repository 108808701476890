import { Link } from "react-router-dom";
import React from "react";

export default function Header({
  icon,
  children,
  profile_image,
  line_oa,
  isQuestion,
}) {
  return (
    <div className={`${isQuestion ? "header-step-question" : "header-step"}`}>
      {isQuestion ? (
        <div className="container d-flex">
          <span className="icon me-3 d-inline-block">{icon}</span>
          <h2 className="d-inline-block">{children}</h2>
        </div>
      ) : (
        <div className="container h-100 row align-items-center">
          <div>
            <span className="icon me-3">{icon}</span>
            <h2 className="d-inline-block">{children}</h2>
          </div>
        </div>
      )}
      {profile_image && (
        <Link to={`/${line_oa}/profile`}>
          <div className="profile">
            <div className="mt-2 text-center">
              <div className="border-gradient d-inline-block">
                <img src={profile_image} />
              </div>
              <p className="mt-1">บัญชีผู้ใช้</p>
            </div>
          </div>
        </Link>
      )}
    </div>
  );
}
