import ProductReportResultComponent from "../pages/productReportResult";
import withAuth from "../../hoc/withAuth";
import withLineInit from "../../hoc/lineInit";

const ProductReportResultPage = () => {
  return (
    <>
      <ProductReportResultComponent line_oa="fda-thai" />
    </>
  );
};

export default withLineInit(
  withAuth(ProductReportResultPage, "fda-thai"),
  "fda-thai"
);
