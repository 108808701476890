import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

export default function ButtonSelection(props) {
  return (
    <button
      {...props}
      className={`button-selection--${
        props.selected ? "selected" : "primary"
      } ${props.className}`}
    >
      <div className="container">
        <div className="d-flex justify-content-between ">
          <div>
            <FontAwesomeIcon icon={faCheckCircle} className="icon" />
          </div>
          <h4>{props.children}</h4>
          <div>
            <FontAwesomeIcon icon={faCheckCircle} className="icon-hide" />
          </div>
        </div>
      </div>
    </button>
  );
}
