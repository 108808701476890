import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../../components/register/footer";
import Header from "../../components/common/header";
import { Helmet } from "react-helmet";
import { InputField } from "../../components/common/inputField";
import LineInit from "../../hoc/lineInit";
import Select from "react-select";
import SelectDate from "../../components/register/selectdate";
import Swal from "sweetalert2";
import UserService from "../../services/UserService";
import WithAuth from "../../hoc/withAuth";
import cookie from "react-cookies";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import validator from "validator";

const EditProfile = ({ line_oa }) => {
  const history = useHistory();
  const [validemail, setValidemail] = useState(true);
  const [validtel, setValidtel] = useState(true);
  const [categories, setCategories] = useState(null);
  const [province, setProvince] = useState(null);
  const [checkSelected, setCheckSelected] = useState(true);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    getCategories();
    getProvince();
  }, []);

  const customStylesSelect = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      if (data.id === province[province.length - 1].id) {
        return {
          ...styles,
          backgroundColor: "none",
          color: "#464646",
          padding: "15px 20px",
          "&:hover": {
            backgroundColor: "#B7D3FB",
          },
        };
      }
      return {
        ...styles,
        backgroundColor: "none",
        borderBottom: "1px solid #E5E5E5",
        color: "#464646",
        padding: "15px 20px",
        "&:hover": {
          backgroundColor: "#B7D3FB",
        },
      };
    },
  };

  const getProfile = async (categorie) => {
    await UserService.profile(cookie.load("token")).then(async (res) => {
      setProfile({
        ...res.data,
        province: {
          ...res.data.province,
          label: res.data.province.name,
          value: res.data.province.id,
        },
      });
      const data = await categorie.map((category, key) => {
        for (let index = 0; index < res.data.interests.length; index++) {
          if (category.id === res.data.interests[index].category_id) {
            return {
              ...category,
              selected: true,
            };
          }
        }
        return {
          ...category,
          selected: false,
        };
      });
      setCategories(data);
    });
  };

  const getCategories = async () => {
    await UserService.category().then((res) => {
      getProfile(res.data);
    });
  };

  const getProvince = async () => {
    await UserService.province().then(async (res) => {
      let data = res.data;
      data = await data.map((province, key) => {
        return {
          ...province,
          value: province.id,
          label: province.name,
        };
      });
      setProvince(data);
    });
  };

  const validEmail = () => {
    setValidemail(false);
    if (validator.isEmail(profile.email)) {
      setValidemail(true);
    }
  };

  const validTel = () => {
    setValidtel(false);
    if (validator.isMobilePhone(profile.tel, "any")) {
      if (validator.isLength(profile.tel, 10, 10)) {
        setValidtel(true);
      }
    }
  };

  const onSelectCategory = (key) => {
    categories[key].selected = !categories[key].selected;
    setCategories([...categories]);
    let categories_selected = categories.filter(
      (category) => category.selected
    );
    if (categories_selected.length !== 0) {
      setCheckSelected(true);
    } else {
      setCheckSelected(false);
    }
  };

  const updateProfile = async () => {
    let categories_selected = categories.filter(
      (category) => category.selected
    );
    if (categories_selected.length !== 0) {
      let data = {
        ...profile,
        birthdate: profile.birthdate.substring("0", "10"),
        province_id: profile.province.id,
        interests: categories_selected,
      };
      console.log(data);
      await UserService.update_profile(data, cookie.load("token")).then(
        (res) => {
          console.log(res);
          Swal.fire({
            icon: "success",
            text: "บันทึกข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            history.push(`/${line_oa}/profile`);
          });
        }
      );
    }
  };

  const CategoryBox = ({ category, key_array, onSelectCategory }) => {
    return (
      <div
        className={`category ${category.selected && "category-selected"}`}
        onClick={() => {
          onSelectCategory(key_array);
        }}
      >
        <div className="check">
          <FontAwesomeIcon icon={faCheckCircle} />
        </div>
        <img
          src={process.env.REACT_APP_IMAGE_URL + "/" + category.icon_url}
          className="w-50"
        />
        <p>{category.display_name}</p>
      </div>
    );
  };

  return (
    <div id="editprofile">
      <Helmet>
        <title>แก้ไขข้อมูลส่วนตัว</title>
      </Helmet>
      <Header onBack={() => history.push(`/${line_oa}/profile`)} />
      {profile && categories && (
        <>
          <div className="container mt-3">
            <div className="mb-3">
              <InputField
                type="text"
                name="ชื่อ"
                placeholder="ชื่อ"
                value={profile.first_name}
                change={(first_name) =>
                  setProfile({ ...profile, first_name: first_name })
                }
                require={true}
              />
            </div>
            <div className="mb-3">
              <InputField
                type="text"
                name="นามสกุล"
                placeholder="นามสกุล"
                value={profile.last_name}
                change={(last_name) =>
                  setProfile({ ...profile, last_name: last_name })
                }
                require={true}
              />
            </div>
            <div className="mb-3">
              <label for="label">เพศ</label>
              <div className="col-12 row m-0">
                <div className="col-4 ps-0">
                  <button
                    className={`button--${
                      profile.gender === "M" ? "blue" : "nocolor"
                    } w-100`}
                    onClick={() => setProfile({ ...profile, gender: "M" })}
                  >
                    <h4>ชาย</h4>
                  </button>
                </div>
                <div className="col-4 px-1">
                  <button
                    className={`button--${
                      profile.gender === "F" ? "blue" : "nocolor"
                    } w-100`}
                    onClick={() => setProfile({ ...profile, gender: "F" })}
                  >
                    <h4>หญิง</h4>
                  </button>
                </div>
                <div className="col-4 pe-0">
                  <button
                    className={`button--${
                      profile.gender === "O" ? "blue" : "nocolor"
                    } w-100`}
                    onClick={() => setProfile({ ...profile, gender: "O" })}
                  >
                    <h4>ไม่ระบุ</h4>
                  </button>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label for="label">วันเกิด</label>
              <div className="w-100">
                <SelectDate
                  disabled={false}
                  label="Date of birth"
                  onChange={(value) =>
                    setProfile({ ...profile, birthdate: value })
                  }
                  onFocus={() => console.log("is focussed")}
                  onBlur={() => {
                    if (!profile.birthdate) {
                      console.log("blur");
                    }
                  }}
                  birthdate={profile.birthdate}
                />
              </div>
            </div>
            <div className="mb-3">
              <InputField
                type="text"
                name="เบอร์โทร"
                placeholder="เบอร์โทร"
                value={profile.tel}
                change={(tel) => setProfile({ ...profile, tel: tel })}
                require={true}
                onBlur={() => validTel()}
              />
              {!validtel && <div style={{ color: "red" }}>tel error</div>}
            </div>
            <div className="mb-3">
              <InputField
                type="text"
                name="อีเมล"
                placeholder="อีเมล"
                value={profile.email}
                change={(email) => setProfile({ ...profile, email: email })}
                require={true}
                onBlur={() => validEmail()}
              />
              {!validemail && <div style={{ color: "red" }}>email error</div>}
            </div>
            <div className="custom-select mb-3">
              <label for="label">จังหวัด</label>
              {console.log(profile.province)}
              <Select
                isSearchable={false}
                value={profile.province}
                defaultValue={profile.province}
                onChange={(e) => setProfile({ ...profile, province: e })}
                options={province}
                placeholder={"จังหวัด"}
                styles={customStylesSelect}
              />
            </div>
            <div className="mb-3">
              <label for="label">หัวข้อที่สนใจ</label>
              <div className="col-12 mt-3">
                <div className="row category-list">
                  {categories &&
                    categories.map((category, key) => {
                      let props = {
                        category: category,
                        key_array: key,
                        onSelectCategory: onSelectCategory,
                      };
                      return (
                        <div className="col-4 py-2 px-1">
                          <CategoryBox {...props} />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <Footer
            disabled={
              !checkSelected ||
              !profile.first_name ||
              !profile.last_name ||
              !profile.email ||
              !profile.gender ||
              !profile.province_id ||
              !profile.tel ||
              !profile.birthdate
            }
            onNext={() => updateProfile()}
          >
            บันทึก
          </Footer>
        </>
      )}
    </div>
  );
};

export default EditProfile;
