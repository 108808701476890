import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export default function Footer({ onNext, children, disabled }) {
  return (
    <div className={`footer w-100 py-3`}>
      <div className="container">
        <button
          disabled={disabled}
          onClick={() => onNext()}
          className={`button w-100 ${disabled && "button-disabled"}`}
        >
          {children}
        </button>
      </div>
    </div>
  );
}
