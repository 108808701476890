import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../common/header";
import HeaderStep from "../common/headerStep";
import QuestionComponent from "./question";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";

export default function Step3({
  questions,
  formStep,
  report,
  setReport,
  onChange,
  onBack,
}) {
  const [answers, setAnswers] = useState(report.answers);
  const [question, setQuestion] = useState(
    formStep === 2 ? 0 : questions.length - 1
  );

  useEffect(() => {
    setAnswers(report.answers);
  }, [report.answers]);

  const addAnswer = (question_id, answer_id) => {
    let answers_copy = [...answers];
    if (answers.length > 0) {
      answers_copy[question] = {
        no: question_id,
        answer: answer_id,
      };
    } else {
      answers_copy.push({
        no: question_id,
        answer: answer_id,
      });
    }
    setReport({
      ...report,
      answers: answers_copy,
    });
  };

  const onBackQuestion = () => {
    if (question != 0) {
      setQuestion(question - 1);
    } else {
      onBack();
    }
  };

  const onNext = () => {
    if (
      report.answers.length !== questions.length ||
      question !== questions.length - 1
    ) {
      if (answers[question] === undefined) {
        window.alert("please select");
      } else {
        setQuestion(question + 1);
      }
    } else {
      onChange();
    }
  };

  return (
    <div>
      <Header onBack={onBackQuestion} />
      <HeaderStep icon={<FontAwesomeIcon icon={faCommentDots} />} isQuestion={true}>
        {questions[question].question_name}
      </HeaderStep>
      <div className="container">
        <QuestionComponent
          question={questions[question]}
          answer_selected={answers[question]}
          addAnswer={addAnswer}
          onNext={onNext}
        />
      </div>
    </div>
  );
}
