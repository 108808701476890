import axios from "axios";

const UserService = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/user`,
});

const UserServiceWithAuth = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/user/auth`,
});

const Service = {
  login: (data) => UserService.post("/login", data),
  check_user: (uid) =>
    UserService.get("/check", {
      headers: {
        UID: uid,
      },
    }),
  user_register_fdathai: (data) => UserService.post("/register/fda", data),
  user__register_fdapeople: (data) =>
    UserService.post("/register/fda-people", data),
  update_profile: (data, token) =>
    UserServiceWithAuth.put("/profile", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  profile: (token) =>
    UserServiceWithAuth.get("/profile", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),

  product_report: (data, token) =>
    UserServiceWithAuth.post("/product-report", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  product_report_by_id: (id, token) =>
    UserService.get(`/product-report/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  product_report_by_id_sendbot: (id, token) =>
    UserService.get(`/product_report/${id}/sendBot`),
  product_report_by_id_sendmail: (id, token, data) =>
    UserService.post(`/product-report/${id}/send-mail`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  product_report_by_userid: (token) =>
    UserServiceWithAuth.get(`/product-report`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  category: (_) => UserService.get("/category/all"),
  province: (_) => UserService.get("/province/all"),
  question: (category_id, subcategory_id) =>
    UserService.get(`/question/all/${category_id}?sub=${subcategory_id || ""}`),

  // behavier: (data, headers) =>
  //   UserService.post("/auth/behavior", data, headers),
  rewards: (_) => UserService.get("/reward/all"),
  redeem_reward: (id, data, token) =>
    UserServiceWithAuth.post(`/reward/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  my_rewards: (token) =>
    UserServiceWithAuth.get(`/reward/log`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  survey: (token, slug) =>
    UserService.get(`/survey/detail/${slug}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  survey_submit: (token, slug, data) =>
    UserService.post(`/survey/answer/${slug}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default Service;
