import SurveyPageComponent from "../pages/survey";
import withAuth from "../../hoc/withAuth";
import withLineInit from "../../hoc/lineInit";

const SurveyPage = ({ line_profile }) => {
  return (
    <>
      <SurveyPageComponent line_profile={line_profile} line_oa="fda-thai" />
    </>
  );
};
export default withLineInit(withAuth(SurveyPage, "fda-thai"), "fda-thai");
