import RegisterPageComponent from "../pages/register";
import withLineInit from "../../hoc/lineInit";
import withNotUser from "../../hoc/withNotUser";

const RegisterPage = ({ line_profile }) => {
  return (
    <>
      <RegisterPageComponent line_profile={line_profile} line_oa="fda-thai" />
    </>
  );
};

export default withLineInit(withNotUser(RegisterPage, "fda-thai"), "fda-thai");
