import ProfilePageComponent from "../pages/profile";
import withAuth from "../../hoc/withAuth";
import withLineInit from "../../hoc/lineInit";

const ProfilePage = ({ line_profile }) => {
  return (
    <>
      <ProfilePageComponent line_profile={line_profile} line_oa="fda-people" />
    </>
  );
};
export default withLineInit(withAuth(ProfilePage, "fda-people"), "fda-people");
