import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import EditProfileFDAPeoplePage from "./pages/fda-people/editProfile";
import EditProfileFDAThaiPage from "./pages/fda-thai/editProfile";
import HistoryReportFDAPeoplePage from "./pages/fda-people/historyReport";
import HistoryReportFDAThaiPage from "./pages/fda-thai/historyReport";
import HomeFDAPeoplePage from "./pages/fda-people/index";
import HomeFDAThaiPage from "./pages/fda-thai/index";
import HomePage from "./pages/index";
import ProductReportFDAPeoplePage from "./pages/fda-people/productReport";
import ProductReportFDAThaiPage from "./pages/fda-thai/productReport";
import ProductReportResultFDAPeoplePage from "./pages/fda-people/productReportResult";
import ProductReportResultFDAThaiPage from "./pages/fda-thai/productReportResult";
import ProfileFDAPeoplePage from "./pages/fda-people/profile";
import ProfileFDAThaiPage from "./pages/fda-thai/profile";
import React from "react";
import RegisterFDAPeoplePage from "./pages/fda-people/register";
import RegisterFDAThaiPage from "./pages/fda-thai/register";
import SurveyFDAThaiPage from "./pages/fda-thai/survey";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={(props) => <HomePage {...props} />} />
        {/* fda-people */}
        <Route
          exact
          path="/fda-people/profile"
          render={(props) => <ProfileFDAPeoplePage {...props} />}
        />
        <Route
          exact
          path="/fda-people/history-report"
          render={(props) => <HistoryReportFDAPeoplePage {...props} />}
        />
        <Route
          exact
          path="/fda-people/product-report"
          render={(props) => <ProductReportFDAPeoplePage {...props} />}
        />
        <Route
          exact
          path="/fda-people/product-report-result/:id"
          render={(props) => <ProductReportResultFDAPeoplePage {...props} />}
        />
        <Route
          exact
          path="/fda-people/register"
          render={(props) => <RegisterFDAPeoplePage {...props} />}
        />
        <Route
          exact
          path="/fda-people/edit-profile"
          render={(props) => <EditProfileFDAPeoplePage {...props} />}
        />
        <Route
          exact
          path="/fda-people"
          render={(props) => <HomeFDAPeoplePage {...props} />}
        />
        {/* fda-thai */}
        <Route
          exact
          path="/fda-thai/profile"
          render={(props) => <ProfileFDAThaiPage {...props} />}
        />
        <Route
          exact
          path="/fda-thai/history-report"
          render={(props) => <HistoryReportFDAThaiPage {...props} />}
        />
        <Route
          exact
          path="/fda-thai/product-report"
          render={(props) => <ProductReportFDAThaiPage {...props} />}
        />
        <Route
          exact
          path="/fda-thai/product-report-result/:id"
          render={(props) => <ProductReportResultFDAThaiPage {...props} />}
        />
        <Route
          exact
          path="/fda-thai/register"
          render={(props) => <RegisterFDAThaiPage {...props} />}
        />
        <Route
          exact
          path="/fda-thai/edit-profile"
          render={(props) => <EditProfileFDAThaiPage {...props} />}
        />
        <Route
          exact
          path="/fda-thai/survey/:slug"
          render={(props) => <SurveyFDAThaiPage {...props} />}
        />
        <Route
          exact
          path="/fda-thai"
          render={(props) => <HomeFDAThaiPage {...props} />}
        />
      </Switch>
    </Router>
  );
}

export default Routes