import React, { Component, useEffect, useState } from "react";

import { Redirect } from "react-router-dom";
import UserService from "../services/UserService";
import cookie from "react-cookies";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const withNotUser = (WrappedComponent, line_oa) => (props) => {
  const [state, setState] = useState({ loading: true, isUser: false });
  let query = useQuery();

  useEffect(() => {
    CheckUser();
  }, []);

  const CheckUser = async () => {
    await UserService.check_user(props.line_profile.userId)
      .then((res) => {
        cookie.save("token", res.data.token);
        setState({ isUser: true, loading: false });
      })
      .catch((_) => {
        cookie.remove("token");
        setState({ ...state, loading: false });
      });
  };

  if (!state.loading) {
    if (state.isUser) {
      if (query.get("redirect")) {
        return <Redirect exact to={query.get("redirect")} />;
      } else {
        return <Redirect exact to={`/${line_oa}/product-report`} />;
      }
    } else {
      return <WrappedComponent {...props} />;
    }
  }
  return <div></div>;
};

export default withNotUser;
