import React, { useEffect, useState } from "react";
import { faFrown, faMeh, faSmile } from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../../components/common/header";
import { Helmet } from "react-helmet";
import ReactLoading from "react-loading";
import UserService from "../../services/UserService";
import cookie from "react-cookies";
import moment from "moment";
import { useHistory } from "react-router";

const ReportBox = ({ report }) => {
  const [date, setDate] = useState({
    date: moment(report.created_at).date(),
    month: "ม.ค.",
    year: moment(report.created_at).year() + 543,
  });
  const [level, setLevel] = useState({
    level: "low",
    levelText: "ระดับความเสี่ยงต่ำ",
    icon: faSmile,
  });

  useEffect(() => {
    setDate({ ...date, month: getMonth() });
    setLevel(getLevel());
  }, []);

  const getMonth = () => {
    switch (moment(report.created_at).month() + 1) {
      case 1:
        return "ม.ค.";
      case 2:
        return "ก.พ.";
      case 3:
        return "มี.ค.";
      case 4:
        return "เม.ย.";
      case 5:
        return "พ.ค.";
      case 6:
        return "มิ.ย.";
      case 7:
        return "ก.ค.";
      case 8:
        return "ส.ค.";
      case 9:
        return "ก.ย.";
      case 10:
        return "ต.ค.";
      case 11:
        return "พ.ย.";
      default:
        return "ธ.ค.";
    }
  };

  const getLevel = () => {
    switch (report.level) {
      case "LOW_LEVEL":
        return {
          level: "low",
          levelText: "ระดับความเสี่ยงต่ำ",
          icon: faSmile,
        };
      case "MEDIUM_LEVEL":
        return {
          level: "medium",
          levelText: "ระดับความเสี่ยงปานกลาง",
          icon: faMeh,
        };
      default:
        return {
          level: "high",
          levelText: "ระดับความเสี่ยงสูง",
          icon: faFrown,
        };
    }
  };

  return (
    <div className="py-4 d-flex d-inline-block">
      <Helmet>
        <title>ประวัติการแจ้งเรื่อง</title>
      </Helmet>
      <img
        src={process.env.REACT_APP_IMAGE_URL + report.image_url}
        style={{ objectFit: "cover" }}
      />
      <div className="ms-3">
        <span className="name">{report.product_name}</span>
        <br />
        <span className="date">
          {date.date} {date.month} {date.year}
        </span>
        <br />
        <div className="d-flex d-inline-block mt-1">
          <div className={`icon-box icon-box-${level.level}`}>
            <FontAwesomeIcon className="icon" icon={level.icon} />
          </div>
          <span className="ms-2 pt-1">{level.levelText}</span>
        </div>
      </div>
    </div>
  );
};

const HistoryReportPage = ({ line_oa }) => {
  let history = useHistory();
  const [reports, setReports] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getHistoryReport();
  }, []);

  const getHistoryReport = async () => {
    await UserService.product_report_by_userid(cookie.load("token"))
      .then((res) => {
        setReports(res.data);
        setLoading(false);
      })
      .catch((err) => {
        switch (err.response.status) {
          case 404:
            setLoading(false);
            break;
          default:
            break;
        }
      });
  };

  return (
    <div id="history-report">
      <Header onBack={() => history.push(`/${line_oa}/profile`)} />
      <div className="container py-3">
        <h3>ประวัติการตรวจสอบ</h3>
        {!loading ? (
          <>
            {reports ? (
              reports.map((report, key) => {
                return (
                  <div className="box">
                    <ReportBox report={report} />
                  </div>
                );
              })
            ) : (
              <div className="text-center">
                <div className="pt-4">
                  <img src="/icon/report-icon.png" width="80px"></img>
                </div>
                <div className="pt-3">ยังไม่มีประวัติการตรวจสอบ</div>
              </div>
            )}
          </>
        ) : (
          <div className="text-center pt-5">
            <ReactLoading
              type={"balls"}
              color={"#B4BECB"}
              width={40}
              className="mx-auto"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoryReportPage;
