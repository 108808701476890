import React, { useEffect, useState } from "react";

import Error from "../../components/survey/error";
import Form from "../../components/survey/Form";
import { Helmet } from "react-helmet";
import NotFound from "../../components/survey/notfound";
import ReactLoading from "react-loading";
import Thankyou from "../../components/survey/thankyou";
import UserService from "../../services/UserService";
import cookie from "react-cookies";
import liff from "@line/liff";
import { useParams } from "react-router-dom";

const ProductReportPage = ({ line_profile, line_oa }) => {
  let { slug } = useParams();
  const [step, setStep] = useState(1);
  const [questions, setQuestions] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getQuestions();
  }, []);

  const getQuestions = async () => {
    await UserService.survey(cookie.load("token"), slug)
      .then((res) => {
        setQuestions(res.data.survey_questions);
        setLoading(false);
      })
      .catch((err) => {
        switch (err.response.status) {
          case 410:
            setStep(2);
            break;
          case 404:
            setStep(4);
            break;
          case 500:
            setStep(3);
            break;
          default:
            break;
        }
        setLoading(false);
      });
  };

  const onSubmit = (answers) => {
    UserService.survey_submit(cookie.load("token"), slug, answers)
      .then((res) => setStep(2))
      .catch((err) => {
        setStep(3);
      });
  };

  const onClose = () => {
    liff.closeWindow();
  };

  const Step = () => {
    switch (step) {
      case 1:
        return <Form questions={questions} onSubmit={onSubmit} />;
      case 2:
        return <Thankyou onClose={onClose} />;
      case 3:
        return <Error onClose={onClose} />;
      case 4:
        return <NotFound onClose={onClose} />;
      default:
        break;
    }
  };

  return (
    <>
      <Helmet>
        <title>แบบสอบถาม</title>
      </Helmet>
      {!loading ? (
        <div id="product-report">{Step()}</div>
      ) : (
        <div className="text-center pt-5">
          <ReactLoading
            type={"balls"}
            color={"#B4BECB"}
            width={40}
            className="mx-auto"
          />
        </div>
      )}
    </>
  );
};

export default ProductReportPage;
