import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../common/header";
import HeaderStep from "../common/headerStep";
import QuestionComponent from "./question";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";

export default function Form({ questions, onSubmit }) {
  const [answers, setAnswers] = useState({});
  const [question, setQuestion] = useState(0);

  const addAnswer = (question_id, answer_id) => {
    setAnswers({
      ...answers,
      [question_id]: [answer_id],
    });
  };

  const onBackQuestion = () => {
    setQuestion(question - 1);
  };

  const onNext = () => {
    if (answers[questions[question].id] && answers[questions[question].id][0]) {
      if (questions[questions.length - 1].id === questions[question].id) {
        onSubmit(answers);
      } else {
        setQuestion(question + 1);
      }
    }
  };

  return (
    <div>
      <Header onBack={question === 0 ? null : onBackQuestion} />
      <HeaderStep
        icon={<FontAwesomeIcon icon={faCommentDots} />}
        isQuestion={true}
      >
        {questions[question].question_content}
      </HeaderStep>
      <div className="container">
        <QuestionComponent
          question={questions[question]}
          answer_selected={
            answers[questions[question].id] &&
            answers[questions[question].id][0]
          }
          addAnswer={addAnswer}
          onNext={onNext}
        />
      </div>
    </div>
  );
}
