import Button from "../common/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../common/header";
import HeaderStep from "../common/headerStep";
import React from "react";
import Select from "react-select";
import { faPen } from "@fortawesome/free-solid-svg-icons";

export default function Step1({
  categories,
  report,
  setReport,
  onChange,
  line_profile,
  line_oa,
}) {
  const onSelectCategory = (e) => {
    setReport({
      ...report,
      category: e,
      sub_category: null,
      product: {
        name: "",
        detail: "",
        image: "",
      },
      answers: [],
      location: {
        latitude: "",
        longitude: "",
      },
    });
  };

  const onSelectSubCategory = (e) => {
    setReport({
      ...report,
      sub_category: e,
      product: {
        name: "",
        detail: "",
        image: "",
      },
      answers: [],
      location: {
        latitude: "",
        longitude: "",
      },
    });
  };

  const customStylesCategories = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      if (data.id === categories[categories.length - 1].id) {
        return {
          ...styles,
          backgroundColor: "none",
          color: "#464646",
          padding: "15px 20px",
          "&:hover": {
            backgroundColor: "#B7D3FB",
          },
        };
      }
      return {
        ...styles,
        backgroundColor: "none",
        borderBottom: "1px solid #E5E5E5",
        color: "#464646",
        padding: "15px 20px",
        "&:hover": {
          backgroundColor: "#B7D3FB",
        },
      };
    },
  };

  const customStylesSubCategories = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      if (
        data.id ===
        report.category?.sub_categories[
          report.category?.sub_categories.length - 1
        ].id
      ) {
        return {
          ...styles,
          backgroundColor: "none",
          color: "#464646",
          padding: "15px 20px",
          "&:hover": {
            backgroundColor: "#B7D3FB",
          },
        };
      }
      return {
        ...styles,
        backgroundColor: "none",
        borderBottom: "1px solid #E5E5E5",
        color: "#464646",
        padding: "15px 20px",
        "&:hover": {
          backgroundColor: "#B7D3FB",
        },
      };
    },
  };

  return (
    <div id="report">
      <Header />
      <HeaderStep
        line_oa={line_oa}
        icon={<FontAwesomeIcon icon={faPen} />}
        profile_image={line_profile?.pictureUrl || "/profile.png"}
      >
        ข้อมูลผลิตภัณฑ์
      </HeaderStep>
      <div className="container content" id="select">
        <label className="w-100">
          กรุณาเลือกหมวดหมู่หลักของผลิตภัณฑ์
          <span style={{ color: "red" }}> *</span>
        </label>
        <div>
          <Select
            isSearchable={false}
            value={report.category}
            defaultValue={report.category}
            placeholder={"หมวดหมู่หลัก"}
            onChange={(e) => onSelectCategory(e)}
            options={categories}
            styles={customStylesCategories}
            // menuIsOpen={true}
          />
        </div>
        {report?.category && report?.category?.sub_categories?.length > 0 && (
          <div className="custom-select mt-3">
            <Select
              // isDisabled={!report.category}
              isSearchable={false}
              value={report.sub_category}
              defaultValue={report.sub_category}
              placeholder={"หมวดหมู่ย่อย"}
              onChange={(e) => onSelectSubCategory(e)}
              options={report.category?.sub_categories}
              styles={customStylesSubCategories}
            />
          </div>
        )}
      </div>
      <div className="container fixed-button-bottom">
        <Button
          disabled={
            report.category
              ? report.category.sub_categories.length > 0
                ? !report.sub_category
                : false
              : true
          }
          onClick={() => onChange()}
          className="w-100"
        >
          ถัดไป
        </Button>
      </div>
    </div>
  );
}
