import EditProfilePageComponent from "../pages/editProfile";
import withAuth from "../../hoc/withAuth";
import withLineInit from "../../hoc/lineInit";

const EditProfilePage = () => {
  return (
    <>
      <EditProfilePageComponent line_oa="fda-people" />
    </>
  );
};

export default withLineInit(
  withAuth(EditProfilePage, "fda-people"),
  "fda-people"
);
