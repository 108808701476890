import React, { useState } from "react";

function InputField({
  name,
  type,
  value,
  change,
  placeholder,
  require,
  onBlur,
}) {
  return (
    <div id="inputtext">
      <label for="label">
        {name}
        <span className={`${!require && "d-none"}`} style={{ color: "red" }}>
          {" "}
          *
        </span>
      </label>
      <input
        className="input"
        type={type}
        value={value}
        name="label"
        onChange={(e) => change(e.target.value)}
        placeholder={placeholder}
        onBlur={onBlur}
      />
    </div>
  );
}

function InputFieldPass({ name, type, value, change, placeholder }) {
  return (
    <div id="inputtext">
      <label for="label">{name}</label>
      <input
        className={`input ${value !== "" ? "indent" : ""}`}
        type={type}
        value={value}
        name="label"
        onChange={(e) => change(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
}

function TextArea({ name, value, change, placeholder, require }) {
  return (
    <div id="textarea">
      <label for="label">
        {name}
        <span className={`${!require && "d-none"}`} style={{ color: "red" }}>
          {" "}
          *
        </span>
      </label>
      <textarea
        value={value}
        name="label"
        onChange={(e) => change(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
}
export { InputField, TextArea, InputFieldPass };
