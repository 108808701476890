import Button from "../common/button";
import ButtonSelection from "./buttonSelection";

export default function Question({
  question,
  answer_selected,
  addAnswer,
  onBack,
  onNext,
}) {
  return (
    <div>
      <div className="content-back">
        {question.choices.map((answer) => {
          return (
            <div style={{ marginBottom: "16px" }}>
              <ButtonSelection
                selected={
                  answer_selected?.answer &&
                  answer_selected.answer === answer.id
                }
                className="w-100"
                onClick={() => addAnswer(question.id, answer.id)}
              >
                {answer.choice_text}
              </ButtonSelection>
            </div>
          );
        })}
      </div>
      <div className="fixed-button-bottom w-100">
        <div className="container">
          <Button className="w-100" disabled={!answer_selected?.answer} onClick={onNext}>ถัดไป</Button>
        </div>
      </div>
    </div>
  );
}
