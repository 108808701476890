import React from "react";

export default function Loading() {
  return (
    <div id="loading">
      <div className="loading-page">
        <div className="row align-items-center h-100">
          <div className="text-center">
            <p>Loading...</p>
          </div>
        </div>
      </div>
    </div>
  );
}
