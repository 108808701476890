import ProductReportPageComponent from "../pages/productReport";
import withAuth from "../../hoc/withAuth";
import withLineInit from "../../hoc/lineInit";

const ProductReportPage = ({ line_profile }) => {
  return (
    <>
      <ProductReportPageComponent
        line_oa="fda-people"
        line_profile={line_profile}
      />
    </>
  );
};

export default withLineInit(
  withAuth(ProductReportPage, "fda-people"),
  "fda-people"
);
