import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "./footer";
import Header from "../common/header";
import HeaderStep from "../common/headerStep";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";

const CategoryBox = ({ category, key_array, onSelectCategory }) => {
  return (
    <div
      className={`category ${category.selected && "category-selected"}`}
      onClick={() => {
        onSelectCategory(key_array);
      }}
    >
      <div className="check">
        <FontAwesomeIcon icon={faCheckCircle} />
      </div>
      <img
        src={process.env.REACT_APP_IMAGE_URL + "/" + category.icon_url}
        className="category-icon"
      />
      <p>{category.display_name}</p>
    </div>
  );
};

const Interests = ({
  categorie_no,
  setCategorie,
  categories,
  setCategories,
  onRegister,
  onBack,
}) => {
  // const [categories_selected, setCategories_selected] = useState(categories);
  const [checkSelected, setCheckSelected] = useState(categorie_no !== 0);

  const onSelectCategory = (key) => {
    categories[key].selected = !categories[key].selected;
    setCategories([...categories]);
    let categories_selected = categories.filter(
      (category) => category.selected
    );
    if (categories_selected.length !== 0) {
      setCheckSelected(true);
      setCategorie(categories_selected.length);
    } else {
      setCheckSelected(false);
      setCategorie(categories_selected.length);
    }
  };

  return (
    <div>
      <Header onBack={() => onBack()} />
      <HeaderStep icon={<FontAwesomeIcon icon={faLightbulb} />}>
        กรุณาเลือกหัวข้อที่สนใจ
      </HeaderStep>
      <div className="container">
        <div className="col-12 mt-3">
          <div className="row category-list">
            {categories &&
              categories.map((category, key) => {
                let props = {
                  category: category,
                  key_array: key,
                  onSelectCategory: onSelectCategory,
                };
                return (
                  <div className="col-4 py-2 px-1">
                    <CategoryBox {...props} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <Footer disabled={!checkSelected} onNext={() => onRegister()}>
        สมัครสมาชิก
      </Footer>
    </div>
  );
};

export default Interests;
