import HistoryReportPageComponent from "../pages/historyReport";
import withAuth from "../../hoc/withAuth";
import withLineInit from "../../hoc/lineInit";

const HistoryReportPage = () => {
  return (
    <>
      <HistoryReportPageComponent line_oa="fda-thai" />
    </>
  );
};

export default withLineInit(
  withAuth(HistoryReportPage, "fda-thai"),
  "fda-thai"
);
