import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Helmet } from "react-helmet";
import Interests from "../../components/register/interests";
import Loading from "../../components/common/loading";
import Register from "../../components/register/register";
import UserService from "../../services/UserService";
import cookie from "react-cookies";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const RegisterPage = ({ line_profile, line_oa }) => {
  const history = useHistory();
  let query = useQuery();
  const [step, setStep] = useState(1);
  const [authKeyIsWrong, setAuthKeyIsWrong] = useState(false);
  const [categories, setCategories] = useState(null);
  const [province, setProvince] = useState(null);
  const [profile, setProfile] = useState({
    categorie_no: 0,
    email: "",
    first_name: "",
    last_name: "",
    gender: "",
    tel: "",
    birthdate: "",
    province_id: null,
    auth_key: "",
  });
  useEffect(() => {
    getCategories();
    getProvince();
  }, []);

  const getProvince = async () => {
    await UserService.province().then(async (res) => {
      let data = res.data;
      data = await data.map((province, key) => {
        return {
          ...province,
          value: province.id,
          label: province.name,
        };
      });
      setProvince(data);
    });
  };

  const getCategories = async () => {
    await UserService.category().then((res) => {
      setCategories(res.data);
    });
  };

  const onRegister = async () => {
    let categories_selected = categories.filter(
      (category) => category.selected
    );
    if (categories_selected.length !== 0) {
      let data = {
        ...profile,
        province_id: profile.province_id.id,
        uid: line_profile.userId,
        interests: categories_selected,
      };
      if (
        (line_oa !== "fda-thai" && profile.auth_key) ||
        line_oa === "fda-thai"
      ) {
        switch (line_oa) {
          case "fda-thai":
            await UserService.user_register_fdathai(data)
              .then((res) => {
                cookie.save("token", res.data.token);
                if (query.get("redirect")) {
                  history.push(query.get("redirect"));
                } else {
                  history.push(`/${line_oa}/product-report`);
                }
              })
              .catch((res) => {
                console.log(res);
                if (res.response.status === 404) {
                  onAuthKeyWrong();
                }
              });
            break;
          case "fda-people":
            await UserService.user__register_fdapeople(data)
              .then((res) => {
                cookie.save("token", res.data.token);
                if (query.get("redirect")) {
                  history.push(query.get("redirect"));
                } else {
                  history.push(`/${line_oa}/product-report`);
                }
              })
              .catch((res) => {
                console.log(res);
                if (res.response.status === 404) {
                  onAuthKeyWrong();
                }
              });
            break;
          default:
            break;
        }
      }
    } else {
      window.alert("กรุณาเลือกหมวดหมู่ที่สนใจ");
    }
  };

  const onAuthKeyWrong = () => {
    setAuthKeyIsWrong(true);
    setStep(1);
    window.scrollTo(0, document.body.scrollHeight);
  };

  const Step = () => {
    switch (step) {
      case 1:
        return (
          <div id="register" className="bggrey">
            <Register
              onNext={() => setStep(2)}
              province={province}
              profile={profile}
              setProfile={setProfile}
              unite={line_oa !== "fda-thai"}
              line_profile={line_profile}
              authKeyIsWrong={authKeyIsWrong}
            />
          </div>
        );
      case 2:
        return (
          <div id="register" className="bggrey">
            <Interests
              categories={categories}
              setCategories={setCategories}
              onRegister={onRegister}
              onBack={() => setStep(1)}
              categorie_no={profile.categorie_no}
              setCategorie={(no) =>
                setProfile({ ...profile, categorie_no: no })
              }
            />
          </div>
        );
    }
  };

  return (
    <>
      <Helmet>
        <title>สมาชิก</title>
      </Helmet>
      {categories && province ? Step() : <Loading />}
    </>
  );
};

export default RegisterPage;
