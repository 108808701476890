import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import ReactLoading from "react-loading";
import Step1 from "../../components/productReport/Step1";
import Step2 from "../../components/productReport/Step2";
import Step3 from "../../components/productReport/Step3";
import Step4 from "../../components/productReport/Step4";
import Step5 from "../../components/productReport/Step5";
import UserService from "../../services/UserService";
import cookie from "react-cookies";
import { useHistory } from "react-router-dom";

const ProductReportPage = ({ line_profile, line_oa }) => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [categories, setCategories] = useState([
    {
      id: 1,
      name: "A",
      sub_categories: [
        {
          id: 1,
          name: "AA",
        },
      ],
    },
  ]);
  const [questions, setQuestions] = useState(null);
  const [formStep, setFormStep] = useState(0);
  const [report, setReport] = useState({
    category: null,
    sub_category: null,
    product: {
      name: "",
      detail: "",
      image: "",
    },
    answers: [],
    location: {
      latitude: "",
      longitude: "",
    },
  });

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (report.category !== null) {
      if (report.category.sub_categories.length > 0) {
        if (report.sub_category !== null) {
          getQuestions();
        }
      } else {
        getQuestions();
      }
    }
  }, [report.category, report.sub_category]);

  const getCategories = async () => {
    await UserService.category().then(async (res) => {
      let data = res.data;
      data = await data.map((category, key) => {
        let sub_categories = category.sub_categories;
        sub_categories = sub_categories.map((sub, key) => {
          return {
            ...sub,
            value: sub.id,
            label: sub.name,
            key: key,
          };
        });
        return {
          ...category,
          value: category.id,
          label: category.display_name,
          key: key,
          sub_categories: sub_categories,
        };
      });
      setCategories(data);
    });
  };

  const getQuestions = async () => {
    await UserService.question(
      report.category.id,
      report.sub_category?.id
    ).then((res) => {
      setQuestions(res.data);
    });
  };

  const onChangeStep = (toStep) => {
    setFormStep(step);
    setStep(toStep);
  };

  const setAnswer = async (data, answers) => {
    await answers.map((answer, id) => {
      data.append(`answers[${answer.no}][choice_id]`, answer.answer);
    });
    return data;
  };

  const onSubmit = async (latitude, longitude) => {
    let report_submit = report;
    let data = new FormData();
    if (report_submit.product.detail) {
      data.append("detail", report_submit.product.detail);
    }
    if (report_submit.product.image) {
      data.append("image", report_submit.product.image);
    }
    report_submit.location.latitude = latitude + "";
    report_submit.location.longitude = longitude + "";

    data.append("category_id", report_submit.category.id);
    if (report_submit.sub_category) {
      data.append("sub_category_id", report_submit.sub_category.id);
    } else {
      data.append("sub_category_id", "");
    }
    data.append("product_name", report_submit.product.name);
    data.append("latitude", report_submit.location.latitude);
    data.append("longitude", report_submit.location.longitude);
    data = await setAnswer(data, report_submit.answers);

    await UserService.product_report(data, cookie.load("token"))
      .then((res) => {
        history.push(`/${line_oa}/product-report-result/${res.data.data.id}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Step = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            line_profile={line_profile}
            categories={categories}
            report={report}
            setReport={setReport}
            onChange={(_) => onChangeStep(2)}
            line_oa={line_oa}
          />
        );
      case 2:
        return (
          <Step2
            report={report}
            setReport={setReport}
            onChange={(_) => onChangeStep(3)}
            onBack={(_) => onChangeStep(1)}
          />
        );
      case 3:
        return (
          <Step3
            questions={questions}
            formStep={formStep}
            report={report}
            setReport={setReport}
            onChange={(_) => onChangeStep(4)}
            onBack={(_) => onChangeStep(2)}
          />
        );
      case 4:
        return (
          <Step4
            onChange={(_) => onChangeStep(5)}
            onBack={(_) => onChangeStep(3)}
          />
        );
      case 5:
        return <Step5 onChange={onSubmit} />;
      default:
        break;
    }
  };

  return (
    <>
      <Helmet>
        <title>แจ้งตรวจสอบผลิตภัณฑ์</title>
      </Helmet>
      {categories ? (
        <div id="product-report">{Step()}</div>
      ) : (
        <div className="text-center pt-5">
          <ReactLoading
            type={"balls"}
            color={"#B4BECB"}
            width={40}
            className="mx-auto"
          />
        </div>
      )}
    </>
  );
};

export default ProductReportPage;
