import HomePageComponent from "../pages";
import React from "react";
import withLineInit from "../../hoc/lineInit";

const HomePage = () => {
  return (
    <>
      <HomePageComponent line_oa="fda-people" />
    </>
  );
};

export default withLineInit(HomePage, "fda-people");
