import React, { Component } from "react";

import liff from "@line/liff";

const withLineInit = (WrappedComponent, line_oa) => {
  return class ComponentWithLineInit extends Component {
    constructor(props) {
      super(props);
      this.state = {
        profile: null,
      };
    }

    LineInit = async () => {
      let liffId = process.env.REACT_APP_LIFF_FDA_THAI_ID;
      if (line_oa === "fda-people") {
        liffId = process.env.REACT_APP_LIFF_FDA_PEOPLE_ID;
      }
      await liff.init({ liffId: liffId }).then(async (_) => {
        if (liff.isLoggedIn()) {
          const profile = await liff.getProfile();
          this.setState({
            profile: profile,
          });
        } else {
          liff.login();
        }
      });
    };

    componentDidMount() {
      if (liff.isInClient()) {
        this.LineInit();
      }
    }

    render() {
      if (liff.isInClient()) {
        if (this.state.profile) {
          return (
            <WrappedComponent
              {...this.props}
              line_profile={this.state.profile}
            />
          );
        } else {
          return <div></div>;
        }
      } else {
        return (
          <div id="desktop">
            <div className="content">
              <div className="desktop-text text-center">
                <div className="pb-3">
                  <img src="/line-logo.png" className="mx-auto" />
                </div>
                <span>Please open in line application</span>
              </div>
            </div>
          </div>
        );
      }
    }
  };
};

export default withLineInit;
