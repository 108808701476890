import React, { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function NotFound({ onClose }) {
  useEffect(() => {
    setTimeout(() => {
      onClose();
    }, 10000);
  }, []);
  return (
    <div className="thankyou-page">
      <div className="row align-items-center h-100">
        <div className="text-center">
          <FontAwesomeIcon className="icon" icon={faTimesCircle} />
          <p>ไม่มีแบบสอบถามนี้</p>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
