import React, { PureComponent } from "react";

import Moment from "moment";
import classNames from "classnames";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

function elementHasClass(element, classNames, maxParents = 0) {
  let findIndex = 0;
  let didFind = false;
  let currentNode = element;

  // Search element
  didFind = classNames.some((className) =>
    currentNode.className.includes(className)
  );

  // Search parents
  while (!didFind && findIndex < maxParents) {
    findIndex += 1;

    if (currentNode.parentElement) {
      currentNode = currentNode.parentElement;

      didFind = classNames.some((className) =>
        currentNode.className.includes(className)
      );
    }
  }

  return didFind;
}

export default class InputBirthdayPicker extends PureComponent {
  constructor(props) {
    super(props);

    this.toggleFocus = this.toggleFocus.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      showDropdown: false,
      view: "year",
      day: this.props.birthdate
        ? moment(this.props.birthdate)
            .format("Y-MMMM-DD")
            .substring(
              moment(this.props.birthdate).format("Y-MMMM-DD").length - 2,
              moment(this.props.birthdate).format("Y-MMMM-DD").length
            )
        : undefined,
      month: this.props.birthdate
        ? this.checkMonthEn(moment(this.props.birthdate)
            .format("Y-MMMM-DD")
            .substring(
              5,
              moment(this.props.birthdate).format("Y-MMMM-DD").length - 3
            ))
        : undefined,
      year: this.props.birthdate
        ? Number(moment(this.props.birthdate).format("Y-MMMM-DD").substring(0, 4))+543
        : undefined,
    };
  }

  componentDidMount() {
    global.document.addEventListener("click", this.handleClick);
  }

  componentWillUnmount() {
    global.document.removeEventListener("click", this.handleClick);
  }

  handleClick(event) {
    const { disabled } = this.props;
    const { showDropdown } = this.state;
    const { target } = event;

    if (
      !disabled &&
      elementHasClass(target, ["INPUT-BIRTHDAYPICKER__input"], 2)
    ) {
      this.toggleFocus(!showDropdown);
    } else if (
      !disabled &&
      !elementHasClass(
        target,
        [
          "INPUT-BIRTHDAYPICKER__dropdown",
          "INPUT-BIRTHDAYPICKER__info",
          "INPUT-BIRTHDAYPICKER__content",
          "INPUT-BIRTHDAYPICKER__label",
        ],
        4
      )
    ) {
      this.toggleFocus(false);
    }
  }

  onChange() {
    const { onChange } = this.props;
    const { day, month, year } = this.state;
    const { showDropdown } = this.state;
    const nummonTH = this.checkMonthTh(month);
    const yearEN = Number(year)-543;
    if (day && month && year) {
      const all = moment(`${yearEN}-${nummonTH}-${day}`, "Y-MMMM-DD").format(
        "Y-MM-DD"
      );
      onChange(all);
      this.toggleFocus(!showDropdown);
    }
  }

  toggleFocus(value) {
    const { onFocus, onBlur } = this.props;

    this.setState({ showDropdown: value });

    this.setState({ showDropdown: value }, () =>
      value ? onFocus() : onBlur()
    );
  }

  createYears() {
    const range = moment.range(moment().subtract(80, "years"), moment());

    return [...range.by("year")].map((momentObject) => {
      return Number(momentObject.format("Y"))+543;
    });
  }

  createMonths() {
    const { locale } = this.props;
    let months = [];
    moment.months(locale).map((month) => months.push(this.checkMonthEn(month)))
    return months;
  }

  createDays(newMonth = null) {
    const { locale } = this.props;
    const { year } = this.state;
    const month = this.checkMonthTh(newMonth || this.state.month);

    let daysInMonth = moment(month || "January", "MMM").daysInMonth();

    if (
      moment([year]).isLeapYear() &&
      month === moment.months(moment.locale(locale || "en"))[1]
    ) {
      daysInMonth += 1;
    }

    return Array(daysInMonth)
      .fill()
      .map((values, index) => index + 1);
  }

  setView(view) {
    this.setState({ view });
  }
  
  checkMonthTh = (month) => {
    switch(month) {
      case 'มกราคม':
        return 'January';
      case 'กุมภาพันธ์':
        return 'February';
      case 'มีนาคม':
        return 'March';
      case 'เมษายน':
        return 'April';
      case 'พฤษภาคม':
        return 'May';
      case 'มิถุนายน':
        return 'June';
      case 'กรกฏาคม':
        return 'July';
      case 'สิงหาคม':
        return 'August';
      case 'กันยายน':
        return 'September';
      case 'ตุลาคม':
        return 'October';
      case 'พฤศจิกายน':
        return 'November';
      case 'ธันวาคม':
        return 'December';
      default:
        return month;
    };
  };

  checkMonthEn(month) {
    switch(month) {
      case 'January':
        return 'มกราคม';
      case 'February':
        return 'กุมภาพันธ์';
      case 'March':
        return 'มีนาคม';
      case 'April':
        return 'เมษายน';
      case 'May':
        return 'พฤษภาคม';
      case 'June':
        return 'มิถุนายน';
      case 'July':
        return 'กรกฏาคม';
      case 'August':
        return 'สิงหาคม';
      case 'September':
        return 'กันยายน';
      case 'October':
        return 'ตุลาคม';
      case 'November':
        return 'พฤศจิกายน';
      case 'December':
        return 'ธันวาคม';
    }
  }

  setValue(date) {
    const { disabled } = this.props;
    const { view, day, month, year } = this.state;

    if (!disabled) {
      if (view === "day") {
        this.setState({ day: date }, () => this.onChange());
        if (!month) this.setView("month");
      } else if (view === "month") {
        
        const newState = {
          day,
          month: date,
        };

        if (day && this.createDays(date).length < day) {
          newState.day = undefined;
        }

        this.setState(newState, () => this.onChange());
        if (!day) this.setView("day");
        if (!year) this.setView("year");
      } else {
        this.setState({ year: date }, () => this.onChange());
        if (!month) this.setView("month");
      }

    }
  }

  render() {
    const { disabled, className } = this.props;
    const { day, month, year, view, showDropdown } = this.state;

    return (
      <div
        className={classNames(
          "INPUT-BIRTHDAYPICKER",
          { "INPUT-BIRTHDAYPICKER--disabled": disabled },
          className
        )}
      >
        <div className="INPUT-BIRTHDAYPICKER__input">
          <div className="INPUT-BIRTHDAYPICKER__info">
            <div
              className={classNames(
                "INPUT-BIRTHDAYPICKER__info__element",
                view === "day"
              )}
              onClick={() => this.setView("day")}
            >
              {day || "วัน"}
            </div>
            l
            <div
              className={classNames(
                "INPUT-BIRTHDAYPICKER__info__element",
                view === "month"
              )}
              onClick={() => this.setView("month")}
            >
              {month || "เดือน"}
            </div>
            l
            <div
              className={classNames(
                "INPUT-BIRTHDAYPICKER__info__element",
                view === "year"
              )}
              onClick={() => this.setView("year")}
            >
              {year || "ปี"}
            </div>
          </div>
        </div>
        <div
          className={classNames("INPUT-BIRTHDAYPICKER__dropdown", {
            "INPUT-BIRTHDAYPICKER__dropdown--active": showDropdown,
          })}
        >
          <div
            className={classNames("INPUT-BIRTHDAYPICKER__content", {
              "INPUT-BIRTHDAYPICKER__content__item--years": view === "year",
            })}
          >
            {view === "year" &&
              this.createYears().map((year) => {
                return (
                  <div
                    className="INPUT-BIRTHDAYPICKER__content__item INPUT-BIRTHDAYPICKER__content__item--year"
                    key={year}
                    onClick={() => this.setValue(year)}
                  >
                    {year}
                  </div>
                );
              })}

            {view === "month" &&
              this.createMonths().map((month) => {
                return (
                  <div
                    className="INPUT-BIRTHDAYPICKER__content__item INPUT-BIRTHDAYPICKER__content__item--month"
                    key={month}
                    onClick={() => this.setValue(month)}
                  >
                    {month}
                  </div>
                );
              })}
            {view === "day" &&
              this.createDays().map((day) => {
                return (
                  <div
                    className="INPUT-BIRTHDAYPICKER__content__item INPUT-BIRTHDAYPICKER__content__item--day"
                    key={day}
                    onClick={() => this.setValue(day)}
                  >
                    {day}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}
