import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

export default function Header({ onBack, text }) {
  return (
    <div id="header">
      {onBack && (
        <div className="back-bar">
          <div className="container row m-0 align-items-center h-100">
            <button onClick={() => onBack()} className="btn-back">
              <FontAwesomeIcon icon={faChevronLeft} className="icon me-3" />
              <span>{text || "ย้อนกลับ"}</span>
            </button>
          </div>
        </div>
      )}
      <div className="bar"></div>
    </div>
  );
}
