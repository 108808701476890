import { Helmet } from "react-helmet";

const HomePage = ({ line_oa }) => {
  return (
    <div id="index">
      <Helmet>
        <title>Loading...</title>
      </Helmet>
      <div className="content">
        <div className="index-text text-center">
          FDA {line_oa === "fda-people" ? "People" : ""}
          {line_oa === "fda-thai" ? "Thai" : ""}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
