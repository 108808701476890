import React, { useEffect, useMemo, useState } from "react";
import { faFrown, faMeh, faSmile } from "@fortawesome/free-regular-svg-icons";

import Button from "../../components/common/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../../components/common/header";
import HeaderStep from "../../components/common/headerStep";
import { Helmet } from "react-helmet";
import ModalReportProductDanger from "../../components/common/modalReportProductDanger";
import UserService from "../../services/UserService";
import cookie from "react-cookies";
import liff from "@line/liff";
import { useParams } from "react-router";

const ProductReportResultPage = ({ line_oa }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [profile, setProfile] = useState(null);
  const [productReport, setProductReport] = useState(null);

  let { id } = useParams();

  useEffect(() => {
    getProfile();
    getProductReport();
  }, []);

  const getProfile = async () => {
    await UserService.profile(
      cookie.load("token")
      // "$2y$10$j0U7/doJoT5reJYsU0HNZ.ETRLlCOxeXhXfOvYSzCHJmToiqA29qG"
    ).then((res) => {
      setProfile({
        ...res.data,
        full_name: res.data.first_name + " " + res.data.last_name,
      });
    });
  };

  const getProductReport = async () => {
    UserService.product_report_by_id(
      id,
      cookie.load("token")
      // "$2y$10$j0U7/doJoT5reJYsU0HNZ.ETRLlCOxeXhXfOvYSzCHJmToiqA29qG"
    ).then((res) => {
      setProductReport(res.data);
    });
  };

  const getLevel = (productReport) => {
    if (productReport) {
      switch (productReport.level) {
        case "LOW_LEVEL":
          return {
            level: "low",
            levelText: "ระดับความเสี่ยงต่ำ",
            icon: faSmile,
          };
        case "MEDIUM_LEVEL":
          return {
            level: "medium",
            levelText: "ระดับความเสี่ยงปานกลาง",
            icon: faMeh,
          };
        default:
          return {
            level: "high",
            levelText: "ระดับความเสี่ยงสูง",
            icon: faFrown,
          };
      }
    } else {
      return {
        level: "low",
        levelText: "ระดับความเสี่ยงต่ำ",
        icon: faSmile,
      };
    }
  };

  const level = useMemo(() => {
    return getLevel(productReport);
  }, [productReport]);

  const onClose = () => {
    UserService.product_report_by_id_sendbot(
      id,
      cookie.load("token")
      // "$2y$10$j0U7/doJoT5reJYsU0HNZ.ETRLlCOxeXhXfOvYSzCHJmToiqA29qG"
    );
    liff.closeWindow();
  };

  const modalProps = {
    profile: profile,
    level: level,
    line_oa: line_oa,
    productReport: productReport?.data,
    closeModal: setIsShowModal,
  };

  const stylePageModalOpen = {
    overflow: "hidden",
    height: "100vh",
  };

  return (
    <>
      <Helmet>
        <title>แจ้งเรื่อง</title>
      </Helmet>
      <div id="product-report" style={isShowModal ? stylePageModalOpen : null}>
        {productReport && (
          <div>
            {isShowModal && <ModalReportProductDanger {...modalProps} />}
            <Header />
            <HeaderStep />
            <div className="product-detail">
              <div className={`icon-box icon-box-${level.level} mx-auto`}>
                <FontAwesomeIcon icon={level.icon} className="icon" />
              </div>
              <p className="mt-2">{level.levelText}</p>
            </div>
            <div className="container">
              <div className="score-box">
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}/${productReport.data.category.icon_url}`}
                  alt={level.levelText}
                />
                <div className="ms-2">
                  <span className="product-name">
                    {productReport.data.product_name}
                  </span>
                  <br />
                  <span className="product-category">
                    {productReport.data.category.display_name}
                    {productReport.data.sub_category && (
                      <>{` > ${productReport.data.sub_category.name}`}</>
                    )}
                  </span>
                  <br />
                  <span>{productReport.data.detail || "-"}</span>
                </div>
              </div>
            </div>
            {level.level === "high" && (
              <div className="content-warning mt-4">
                <h3 className="header">
                  การตรวจสอบของท่านได้ผลลัพธ์เป็นความเสี่ยงสูง
                </h3>
                <p className="body">
                  ท่านสามารถกดปุ่ม "รายงานผลิตภัณฑ์"
                  <br />
                  เพื่อแจ้งเรื่องให้เจ้าหน้าที่ผู้เกี่ยวข้องดำเนินการต่อไป
                </p>
                <Button
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setIsShowModal(true);
                  }}
                  className="button--red"
                >
                  รายงานผลิตภัณฑ์
                </Button>
              </div>
            )}
            <div
              className={`content-result${
                level.level === "high" ? "-warning" : " mt-4"
              }`}
            >
              <div className="container pt-4">
                <div>
                  <h3 className="suggest">คำแนะนำ</h3>
                  <span className="pt-3 content-suggest">
                    {productReport.suggestion || "-"}
                  </span>
                </div>
              </div>
            </div>
            <div
              className={`container ${
                level.level === "high" ? "footer" : "fixed-button-bottom"
              }`}
              style={{ backgroundColor: "white" }}
            >
              <div className="warning-text py-2">
                *ผลการทดสอบเบื้องต้นนี้ไม่สามารถนำไปเผยแพร่หรืออ้างอิง
                ซึ่งอาจทำให้เกิดความเข้าใจผิดต่อผู้บริโภคตามมา
              </div>
              <Button onClick={() => onClose()} className="w-100">
                ถัดไป
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductReportResultPage;
