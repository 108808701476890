import React, { useEffect, useState } from "react";

// import dayjs from 'dayjs'
import moment from "moment";

export default function RewardCard({
  image,
  name,
  require_score,
  // code,
  end_date,
}) {
  const [reward, setReward] = useState({
    image,
    name,
    require_score,
    // code,
    endDate: {
      date: moment(end_date).date(),
      month: "ม.ค.",
      year: moment(end_date).year() + 543,
    },
  });

  useEffect(() => {
    setReward({ ...reward, endDate: { ...reward.endDate, month: getMonth() } });
  }, []);
  const getMonth = () => {
    switch (moment(end_date).month() + 1) {
      case 1:
        return "ม.ค.";
      case 2:
        return "ก.พ.";
      case 3:
        return "มี.ค.";
      case 4:
        return "เม.ย.";
      case 5:
        return "พ.ค.";
      case 6:
        return "มิ.ย.";
      case 7:
        return "ก.ค.";
      case 8:
        return "ส.ค.";
      case 9:
        return "ก.ย.";
      case 10:
        return "ต.ค.";
      case 11:
        return "พ.ย.";
      default:
        return "ธ.ค.";
    }
  };

  return (
    <div id="rewardcard" className="pt-3 col-12">
      <div className="row">
        <div className="col-3">
          <img
            src={`${process.env.REACT_APP_IMAGE_URL + "/" + reward.image}`}
            width="50px"
            className="borderradius image-center"
          />
        </div>
        <div className="col-9">
          <h4 className="dark-blue-800">{reward.name}</h4>
          <p className="my-1">คะแนน: {reward.require_score}</p>
          {/* <p className={`${code ? "d-block my-1" : "d-none"}`}>code: {code}</p> */}
          <p className="my-1 subbody text-gret">
            หมดเขต {reward.endDate.date} {reward.endDate.month}{" "}
            {reward.endDate.year}
          </p>
        </div>
      </div>
    </div>
  );
}
