import { InputField, TextArea } from "../common/inputField";
import React, { useEffect, useState } from "react";

import Footer from "./footer";
import Header from "../common/header";
import Select from "react-select";
import SelectDate from "./selectdate";
import validator from "validator";

const Register = ({ onNext, province, setProfile, profile, unite, line_profile, authKeyIsWrong }) => {
  const [validemail, setValidemail] = useState(true);
  const [validtel, setValidtel] = useState(true);

  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      if (data.id === province[province.length - 1].id) {
        return {
          ...styles,
          backgroundColor: "none",
          color: "#464646",
          padding: "15px 20px",
          "&:hover": {
            backgroundColor: "#B7D3FB",
          },
        };
      }
      return {
        ...styles,
        backgroundColor: "none",
        color: "#464646",
        borderBottom: "1px solid #E5E5E5",
        padding: "15px 20px",
        "&:hover": {
          backgroundColor: "#B7D3FB",
        },
      }
    },
  };

  const validEmail = () => {
    setValidemail(false);
    if (validator.isEmail(profile.email)) {
      setValidemail(true);
    }
  };

  const validTel = () => {
    setValidtel(false);
    if (validator.isMobilePhone(profile.tel, "any")) {
      if (validator.isLength(profile.tel, 10, 10)) {
        setValidtel(true);
      }
    }
  };

  const validEmailandTel = async () => {
    validEmail();
    validTel();
    if (validemail && validtel) {
      await onNext();
    }
  };

  return (
    <div>
      <Header />
      <div className="container content">
        <div className="bluebg50px text-center py-4">
          <img src={line_profile?.pictureUrl || "/profile.png"} className="profileimage" />
        </div>
        <div className="mb-3">
          <InputField
            type="text"
            name="ชื่อ"
            placeholder="ชื่อ"
            value={profile.first_name}
            change={(first_name) =>
              setProfile({ ...profile, first_name: first_name })
            }
            require={true}
          />
        </div>
        <div className="mb-3">
          <InputField
            type="text"
            name="นามสกุล"
            placeholder="นามสกุล"
            value={profile.last_name}
            change={(last_name) =>
              setProfile({ ...profile, last_name: last_name })
            }
            require={true}
          />
        </div>
        <div className="mb-3">
          <label for="label">เพศ<span style={{color:"red"}}> *</span></label>
          <div className="col-12 row m-0">
            <div className="col-4 ps-0">
              <button
                className={`button--${
                  profile.gender === "M" ? "blue" : "nocolor"
                } w-100`}
                onClick={() => setProfile({ ...profile, gender: "M" })}
              >
                <h4>ชาย</h4>
              </button>
            </div>
            <div className="col-4 px-1">
              <button
                className={`button--${
                  profile.gender === "F" ? "blue" : "nocolor"
                } w-100`}
                onClick={() => setProfile({ ...profile, gender: "F" })}
              >
                <h4>หญิง</h4>
              </button>
            </div>
            <div className="col-4 pe-0">
              <button
                className={`button--${
                  profile.gender === "O" ? "blue" : "nocolor"
                } w-100`}
                onClick={() => setProfile({ ...profile, gender: "O" })}
              >
                <h4>ไม่ระบุ</h4>
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label for="label">วันเกิด<span style={{color:"red"}}> *</span></label>
          <div className="w-100">
            <SelectDate
              disabled={false}
              label="Date of birth"
              onChange={(value) => {
                console.log("value" + value)
                setProfile({ ...profile, birthdate: value })}}
              onFocus={() => console.log("is focussed")}
              onBlur={() => {
                if (!profile.birthdate) {
                  console.log("blur");
                }
              }}
              birthdate={profile.birthdate}
            />
          </div>
        </div>
        <div className="mb-3">
          <InputField
            type="tel"
            name="เบอร์โทรศัพท์"
            placeholder="เบอร์โทรศัพท์"
            value={profile.tel}
            change={(tel) => setProfile({ ...profile, tel: tel })}
            require={true}
            onBlur={() => validTel()}
          />
          {!validtel && <div style={{ color: "red" }}>กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง</div>}
        </div>
        <div className="mb-3">
          <InputField
            type="text"
            name="อีเมล"
            placeholder="อีเมล"
            value={profile.email}
            change={(email) => setProfile({ ...profile, email: email })}
            require={true}
            onBlur={() => validEmail()}
          />
          {!validemail && <div style={{ color: "red" }}>กรุณากรอกอีเมลให้ถูกต้อง</div>}
        </div>
        <div className="mb-3">
          <label for="label">จังหวัด<span style={{color:"red"}}> *</span></label>
          <Select
            onMenuOpen={true}
            isSearchable={false}
            value={profile.province_id}
            defaultValue={profile.province_id}
            onChange={(e) => setProfile({ ...profile, province_id: e })}
            options={province}
            styles={customStyles}
            placeholder={"จังหวัด"}
          />
        </div>
        <div className="mb-3">
          <InputField
            require={unite}
            type="text"
            name="รหัสประจำหน่วยงาน"
            placeholder="รหัสประจำหน่วยงาน"
            value={profile.auth_key}
            change={(auth_key) =>
              setProfile({ ...profile, auth_key: auth_key })
            }
          />
          {authKeyIsWrong && <div style={{ color: "red" }}>รหัสประจำหน่วยงานไม่ถูกต้อง</div>}
        </div>
      </div>
      <Footer
        disabled={
          !profile.first_name ||
          !profile.last_name ||
          !profile.email ||
          !profile.gender ||
          !profile.province_id ||
          !profile.tel ||
          !profile.birthdate ||
		  (unite ? !profile.auth_key : false)
        }
        onNext={() => validEmailandTel()}
      >
        ถัดไป
      </Footer>
    </div>
  );
};

export default Register;
